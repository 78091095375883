<div class="vh-100 dt w-100 black-80 center">
  <div class="dtc v-mid">
    <mat-card class="w-100 w-80-m w-50-l center yt__h5">
      <mat-card-content class="center yt__ma w-80">
        <div class="center yt__ma">
          <form class="measure center" [formGroup]="loginForm">
            <img
              [src]="logoUrl"
              alt="yellow-tin logo"
              class="w-60 w-80-m w-50-l img_display"
              (click)="onLogoClick()"
            />
            <fieldset class="ba b--transparent ph0 mh0 fs_padding">
              <div>
                <label class="db fw6 lh-copy f4 tl mt-3.5">Email</label>

                <input
                  class="yt__h3 b--solid fl yt__scorpion-color w-100 bg-white pa2 br3 b--blue outline-0"
                  type="email"
                  formControlName="email"
                />
              </div>
              <div class="mv5">
                <label class="db fw6 lh-copy f4 tl mt3">Password</label>
                <div class="relative">
                  <input
                    class="yt__h3 b--solid fl yt__scorpion-color w-100 bg-white pa2 br3 b--blue outline-0"
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                  />
                  <a (click)="toggleShowPassword()">
                    <i
                      class="fa fa-fw absolute top-1 right-1 pointer"
                      [ngClass]="{
                        'fa-eye': showPassword,
                        'fa-eye-slash': !showPassword
                      }"
                    ></i>
                  </a>
                </div>
              </div>
              <div *ngIf="loginError">
                <p class="red">username and password are incorrect</p>
              </div>
            </fieldset>
            <button
              mat-raised-button
              color="primary"
              class="w-100 mh3 mb5"
              (click)="onSubmit()"
              [disabled]="!loginForm.valid"
            >
              Sign In
            </button>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
