<div class="form_container">
  <form [formGroup]="resetPassword" class="form_width" autocomplete="off">
    <div class="header">Reset Password</div>
    <div class="padding_top">
      <div class="left_form">
        <mat-form-field
          class="w-100"
          floatLabel="never"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>Current Password</mat-label>
          <input
            matInput
            formControlName="currentPassword"
            [type]="showCurrentPassword ? 'text' : 'password'"
            autocomplete="off"
          />
          <mat-icon
            mat-suffix
            class="mat_icon"
            *ngIf="currentPassword.value"
            (click)="showCurrentPassword = !showCurrentPassword"
          >
            {{ showCurrentPassword ? "visibility" : "visibility_off" }}
          </mat-icon>
        </mat-form-field>

        <mat-form-field
          class="w-100"
          floatLabel="never"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>New Password</mat-label>
          <input
            matInput
            formControlName="newPassword"
            [type]="showPassword ? 'text' : 'password'"
          />
          <mat-icon
            mat-suffix
            class="mat_icon"
            *ngIf="newPassword.value"
            (click)="showPassword = !showPassword"
          >
            {{ showPassword ? "visibility" : "visibility_off" }}
          </mat-icon>
          <mat-hint>Use at least 8 characters</mat-hint>
        </mat-form-field>
      </div>
      <yt-password-meter
        [passwordToCheck]="resetPassword.value.newPassword"
        [barLabel]="barLabel"
      ></yt-password-meter>
    </div>
    <p class="red">{{ resetPasswordStatus }}</p>
    <div class="save_button">
      <button
        mat-raised-button
        class="w-100"
        (click)="onPasswordConfirm()"
        [disabled]="resetPassword.invalid"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
