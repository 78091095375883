import { ResetPasswordModel } from './../login/reset-password/reset-password.model';
import { JWTTokenInfoModel } from './auth.model';
import { ResourceConfig } from '../rest-api/resource.config';
import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
  private token: string;
  private jwtTokenInfo: JWTTokenInfoModel;
  private credentials: string;

  private readonly authenticationUrl =
    this.resourseConfig.BASE_API_URL + 'auth/installerLogin';
  private resetPasswordUrl =
    this.resourseConfig.BASE_API_URL + 'auth/installerChangePwd';

  constructor(
    private resourseConfig: ResourceConfig,
    private httpClient: HttpClient
  ) {}

  login(username: string, password: string) {
    let authCredentials = username + ':' + password;
    this.credentials = authCredentials;

    return this.httpClient.get<{ token: string }>(this.authenticationUrl).pipe(
      tap((data) => {
        let token = data.token;
        if (token) {
          this.token = token;
          let tokenJson: any = jwt_decode(token);
          this.jwtTokenInfo = {
            userName: tokenJson.userName,
            userDisplayName: tokenJson.displayName,
            showDashboard: tokenJson.showDashboard,
            showAnalytics: tokenJson.showAnalytics,
            solarVendorCode: tokenJson.solarVendorCode,
            solarVendorDescription: tokenJson.solarVendorDescription,
          };
          return true;
        } else {
          return false;
        }
      })
    );
  }

  logout(): void {
    this.token = '';
    this.jwtTokenInfo = {} as any;
  }

  getJwtToken() {
    return this.token;
  }

  getJwtTokenInfo() {
    return this.jwtTokenInfo;
  }

  getHttpClientHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.applyAuthorizationHeader(),
    });
  }

  applyAuthorizationHeader(): string {
    if (this.token) {
      return 'Bearer ' + this.token;
    } else {
      return 'Basic ' + btoa(this.credentials);
    }
  }

  resetPassword(resetPasswordData: ResetPasswordModel) {
    return this.httpClient.put<{ message: string }>(
      this.resetPasswordUrl,
      resetPasswordData
    );
  }
}
