import { AuthenticationService } from './../../auth/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetResponse } from './reset-password.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'yt-reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public showPassword = false;
  public showCurrentPassword = false;
  public resetPassword: FormGroup;
  public resetPasswordStatus: string;
  private alive = true;
  barLabel: string = 'Password strength:';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.resetPassword = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      userName: [this.authenticationService.getJwtTokenInfo().userName],
    });
  }

  get newPassword() {
    return this.resetPassword.get('newPassword')!;
  }

  get currentPassword() {
    return this.resetPassword.get('currentPassword')!;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  togglecurrentPassword() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  onPasswordConfirm() {
    let result = this.resetPassword.value;
    this.authenticationService
      .resetPassword(result)
      .pipe(takeWhile((_) => this.alive))
      .subscribe(
        (data: ResetResponse) => {
          this.resetPassword.reset();
          this.snackBar.open(data.message, 'OK');
          this.dialogRef.close();
          setTimeout(() => {
            this.snackBar.dismiss();
          }, 8000);
        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 406) {
            this.resetPasswordStatus = errorResponse.error.message;
          }
        }
      );
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
