<div style="margin-top: 41px" id="strength" #strength>
  <div class="flex bar_alignment mobile_alignment">
    <div class="barLabel">{{ barLabel }}</div>
    <div class="flex bar_alignment">
      <p class="text_style">{{ msg }}</p>
      <ul id="strengthBar">
        <li class="point" [style.background-color]="bar[0]"></li>
        <li class="point" [style.background-color]="bar[1]"></li>
        <li class="point" [style.background-color]="bar[2]"></li>
      </ul>
    </div>
  </div>
</div>
