import { AuthenticationService } from './../auth/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'yt-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public loginFormValue: { email: string; password: string };
  public loginError = false;
  public showPassword = false;
  private alive = true;
  logoUrl: string =
    'https://hp-app.s3.us-west-2.amazonaws.com/logos/YT_LOGO.png';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.loginForm.valueChanges.subscribe(
      (data) => (this.loginFormValue = data)
    );
  }

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get password() {
    return this.loginForm.get('password') as FormControl;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onSubmit() {
    const form = this.loginForm.value;
    this.authenticationService
      .login(form.email, form.password)
      .pipe(takeWhile((_) => this.alive))
      .subscribe(
        (data: any) => {
          let token = data.token;
          let tokenJson: any = jwt_decode(token);
          if (data) {
            if (JSON.parse(tokenJson.showDashboard)) {
              this.router.navigate(['/dashboard/user']);
            } else {
              this.router.navigate(['/dashboard/analytics']);
            }
          } else {
            this.router.navigate(['/']);
          }
        },
        (error: string) => (this.loginError = true)
      );
  }

  onLogoClick(): void {
    window.open('http://www.yellowtin.com', '_blank');
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
